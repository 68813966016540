<template>
  <main class="admin">
    <div class="container">
      <header class="admin__header">
        <img class="admin__logo" :src="logo" alt="Admin page logo" />
      </header>
      <section class="admin__content">
        <span v-if="message" class="helper-text helper-text--success">{{
          message
        }}</span>
        <SubmitButton
          @action="clearLeaderboard"
          text="Clear Leaderboard"
          variant="contained"
        />
        <SubmitButton
          @action="downloadReport"
          text="Download Report"
          variant="contained"
        />
      </section>
    </div>
  </main>
</template>

<script>
import SubmitButton from "../../components/SubmitButton";
import logo from "../../assets/images/icons/logo-with-hand.svg";

export default {
  name: "Admin",
  components: {
    SubmitButton,
  },
  data: () => ({
    message: "",
    error: "",
    logo,
  }),
  methods: {
    async clearLeaderboard() {
      const { message } = await this.$store.dispatch("clearOpenGuessAnswers");
      this.message = message;

      setTimeout(() => {
        this.message = "";
      }, 3000);
    },
    async downloadReport() {
      const response = await this.$store.dispatch("getReport");
      if (!response.error) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.csv");
        link.click();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.admin {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-top: 13.2rem;
}

.container {
  width: 100%;
  max-width: 96.7rem;
  padding-inline: 1.5rem;
  margin-inline: auto;
}

.admin__logo {
  display: block;

  max-width: 100%;
  height: auto;
  margin: 0 auto 21rem;
}

.admin__content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    max-width: 30.3rem;
    width: 100%;
    margin-bottom: 4.3rem;
  }
}

.helper-text {
  position: absolute;

  font-family: "RosarioRegular", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  text-align: center;

  &--success {
    top: -3rem;

    color: #7ac943;
  }
}

@media (max-width: 1440px) {
  .admin {
    padding-top: 16.5rem;
  }

  .admin__content {
    & > button {
      max-width: 38rem;
    }
  }

  .helper-text {
    font-size: 2.25rem;
  }
}

@media (max-width: 991px) {
  .admin {
    padding-top: 10rem;
  }

  .admin__logo {
    max-width: 45rem;
    margin: 0 auto 5rem;
  }

  .admin__content {
    & > button {
      max-width: 25rem;
      margin-bottom: 2.5rem;
    }
  }

  .helper-text {
    font-size: 1.6rem;
  }
}
</style>
